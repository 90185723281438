import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Space, Form, Dropdown, Button } from 'antd'
import { ModuleTopHeading } from '../../components'
import {
    DownOutlined
} from '@ant-design/icons'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import { actionsApi } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );


const BarChartComponent = () => {
    const [form] = Form.useForm()

    const [order, setOrder] = useState(1);
    const dispatch = useDispatch();
    const selectedYear = moment().year();
    const { addchartDetail, filters } = useSelector((state) => state?.chartapi);

    const items = [
        {
            label: <a href="#">Monthly</a>,
            key: '0',
        },
        {
            label: <a href="#">Yearly</a>,
            key: '1',
        },
    ];
   
    const filter = (order) => {
        dispatch(actionsApi?.getChartapiDetail({ year: selectedYear, order }));
        dispatch(actionsApi?.changeAddChartDetailFilters({ ...filters, order }));
    };

    useEffect(() => {
        dispatch(actionsApi?.getChartapiDetail({ year: selectedYear, order: filters?.order }));
        setOrder(filters?.order);
    }, [dispatch, selectedYear, filters]);


    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        filter(key);
      };
    
    const labels = addchartDetail?.map((label) => label.month);

    const data = {
        labels,
        datasets: [
            {
                fill:true,
            label: 'Dataset 1',
            data: addchartDetail?.map((data) => data.count),
            backgroundColor: '#F7D6DA',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'none',
          },
        },
        scales: {
            x: {
              grid: {
                display: false,
              }
            },
            y: {
                grid: {
                  display: false,
                }
              },
        }
      };

    
  return (
    <div>
        <Card className='radius-12 border0 shadow-in'>
            <Row gutter={[24,24]}>
                <Col md={12}>
                    <ModuleTopHeading
                        name='Patient’s Census'
                    />
                </Col>
                <Col md={12}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <Dropdown
                            menu={{
                                items,
                                onClick
                            }}
                            trigger={['click']}
                            arrow
                        >
                            <Button
                                className='margin-top'
                            >
                                Monthly
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <div>
                        <Line options={options} data={data} />
                    </div>
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {BarChartComponent}