import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../../../constants';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd';


export const getAddServicepatientDetail = createAsyncThunk(
    'getServicepatientStatistics',
    async (object, { dispatch }) => {
        const { userToken } = checkAuthorization();
        dispatch(gettingAddServicepatientDetail());

        const myHeaders = new Headers();
        myHeaders.append('Authorization', userToken);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ id: object.id, order: object.order, name: object.name }),
        };

        try {
            const response = await fetch(domainUrl + '/viewSerivesByPatient', requestOptions);
            const result = await response.json();

            if (result.success) {
                let filteredData = result.data;

                // Apply search filter
                if (object.name) {
                    const searchTerm = object.name.toLowerCase();
                    filteredData = filteredData.filter(item => {
                        const doctorName = `${item.doctor.firstName} ${item.doctor.lastName}`.toLowerCase();
                        const nurseName = `${item.nurse.firstName} ${item.nurse.lastName}`.toLowerCase();
                        return doctorName.includes(searchTerm) || nurseName.includes(searchTerm);
                    });
                }

                // Apply order sorting
                if (object.order === '1') {
                    filteredData.sort((a, b) => {
                        const nameA = `${a.doctor.firstName} ${a.doctor.lastName}`.toLowerCase();
                        const nameB = `${b.doctor.firstName} ${b.doctor.lastName}`.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    });
                } else if (object.order === '0') {
                    filteredData.sort((a, b) => {
                        const nameA = `${a.doctor.firstName} ${a.doctor.lastName}`.toLowerCase();
                        const nameB = `${b.doctor.firstName} ${b.doctor.lastName}`.toLowerCase();
                        if (nameA > nameB) return -1;
                        if (nameA < nameB) return 1;
                        return 0;
                    });
                }

                dispatch(addservicepatientDataResponse(result.patient));
                dispatch(addservicepatientDetailResponse(filteredData));
                dispatch(
                    setPages({
                        pageNo: object.pageNo,
                        pageSize: object.pageSize,
                        totalRecords: filteredData.length,
                    })
                );
            } else {
                throw result.message;
            }
        } catch (error) {
            dispatch(addservicepatientDetailClear());
            message.error(error);
        }
    }
);


  export const updatePrescriptionStatus = createAsyncThunk('getStatusStatistics',
    async (object, { dispatch, getState }) => {

        const { userToken } = checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ id: object.id, status: object?.status })
        };
        fetch(domainUrl + `/updatePrescriptionStatus`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                  const data = result?.data
                  dispatch(servicepresstatusResponse({id: data?.id, status: data?.status }))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                message.error(error)
            })
    }
)

const AddServicePatientSlice = createSlice({
  name: 'servicepatient',
  initialState: {
    data: null,
    loading: false,
    addservicepatientDetail: [],
    patientdata: null,
    loadingAddservicepatientDetail: false,
    pagination: {
      pageNo: 1,
      pageSize: 10,
      totalRecords: 0
    },
    filters: {
      name: '',
      order: 1,
      id: '',
      status:''
    }
  },
  reducers: {
    getPatientStatistics: (state) => {
      state.loading = true;
      state.data = null;
    },
    statisticsResponse: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    statisticsClear: (state) => {
      state.data = null;
      state.loading = false;
    },
    gettingAddServicepatientDetail: (state) => {
      state.loadingAddservicepatientDetail = true;
    },
    addservicepatientDetailResponse: (state, action) => {
      state.addservicepatientDetail = action.payload;
      state.loadingAddservicepatientDetail = false;
    },
    addservicepatientDataResponse: (state, action) => {
      state.patientdata = action.payload;
      state.loadingAddservicepatientDetail = false;
    },
    addservicepatientDetailClear: (state) => {
      state.addservicepatientDetail = [];
      state.loadingAddservicepatientDetail = false;
      state.patientdata = null;
    },
    changeAddservicepatientDetailFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPages: (state, action) => {
      state.pagination = action.payload;
    },
    servicepresstatusResponse: (state, action) => {
      const { id, status } = action.payload
      const serviceIndex = state.addservicepatientDetail.findIndex(service => service.id === id)
      if (serviceIndex !== -1) {
          state.addservicepatientDetail[serviceIndex].status = status
      }
      state.loadingAddservicepatientDetail = false
    }
  }
});

export const {
  getPatientStatistics, statisticsResponse, statisticsClear,
  gettingAddServicepatientDetail, addservicepatientDetailResponse, addservicepatientDataResponse, addservicepatientDetailClear,
  changeAddservicepatientDetailFilters, setPages, servicepresstatusResponse
} = AddServicePatientSlice.actions;
export default AddServicePatientSlice.reducer;
