
import { Row, Col, Form, Space, Flex, Checkbox } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { ModuleTopHeadingBtn } from "../../PageComponents/ModuleTopHeadingBtn";
import { HospiceInput } from "../../input";
import { HospiceTimePicker } from "../../Forms";

const AddMedicine = ({ form, handleMedChange }) => {
  
  return (
    <Form.List name="medicines">
      {(fields, { add, remove }) => (
        <Space direction="vertical" className="w-100 mb-3">
          <ModuleTopHeadingBtn name="Medicine" onClick={() => add()} />
          {fields.map(({ key, name }) => (
            <Row key={key} gutter={[16, 0]} align="middle">
              <Col span={24} className="justify-end">
                <Flex justify="end">
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <HospiceInput
                  textArea
                  name={[name, 'medicine']}
                  label='Medicine'
                  placeholder='Add medicine'
                  required
                  message='Please enter medicine'
                  value={form.getFieldValue("medicine") || ''}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <HospiceInput
                  textArea
                  name={[name, 'dosage']}
                  label='Dosage'
                  placeholder='Add dosage'
                  required
                  message='Please enter dosage'
                  value={form.getFieldValue("dosage") || ''}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <HospiceTimePicker
                  datePicker
                  label="From date"
                  name={[name, 'fromDate']}
                  required
                  message="Please select from date"
                  value={form.getFieldValue('fromDate') || ''}
                  onChange={(value) => handleMedChange('fromDate', value, key)}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <HospiceTimePicker
                  datePicker
                  label="To date"
                  name={[name, 'toDate']}
                  required
                  message="Please select to date"
                  value={form.getFieldValue('toDate') || ''}
                  onChange={(value) => handleMedChange('toDate', value, key)}
                />
              </Col>              
              <Col span={24}>
                <Flex vertical>
                    <Form.Item
                        name={[name, 'morning']}
                        valuePropName="checked"
                        className="m-0"
                    >
                        <Checkbox>Morning</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={[name, 'afterNoon']}
                        valuePropName="checked"
                        className="m-0"
                    >
                        <Checkbox>Afternoon</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={[name, 'night']}
                        valuePropName="checked"
                        className="mt-0"
                    >
                        <Checkbox>Night</Checkbox>
                    </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <HospiceInput
                  textArea
                  name={[name, 'note']}
                  label='Note'
                  placeholder='Add note (if any)'
                  value={form.getFieldValue("note") || ''}
                />
              </Col>
            </Row>
          ))}
        </Space>
      )}
    </Form.List>
  );
};

export default AddMedicine;
