import React, { useState } from 'react'
import { Modal, Space, Typography, Button } from 'antd'
// import { apiCalls } from '../../shared/Apis';
import { DeleteOutlined } from '@ant-design/icons';
const { Title, Text } = Typography
const AlertModal = ({onClose, deleteitem}) => {
    
    const [ loading, setLoading ] = useState(false)

    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
    
  return (
    <div>
        <Modal width={500} className='shadow-c modal'  open={deleteitem} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <DeleteOutlined 
                    style={{fontSize: '40px'}} 
                    className='danger-color'
                />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-input text-center'>Are you sure you want to permanently delete this user? <strong>"{deleteitem? deleteitem?.name: ''}"</strong></Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        danger
                        // loading={loading}
                        onClick={()=>{}}
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}