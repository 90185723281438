
//login
import { login, loginResponse, loginClear, AuthCheck, refreshToken } from "./action/Login"
//dashboard
import { getAddPatientDetail, changeAddpatientDetailFilters,updatePatientStatus, patientstatusResponse } from "./action/addpatient"
import { getAddCardDetail } from "./action/cardapi"
import { getChartapiDetail, changeAddChartDetailFilters } from "./action/chartapi"
import { getAddDashboardDetail, changeAdddashboardDetailFilters } from "./action/dashboard"
import { getAddServicepatientDetail, changeAddservicepatientDetailFilters, updatePrescriptionStatus, servicepresstatusResponse } from "./action/servicepatient"
import { getViewMedpresDetail } from "./action/viewmedicineprescription"


export const actionsApi = {
    login, 
    loginResponse, 
    loginClear, 
    AuthCheck, 
    refreshToken,
    //addpatient 
    getAddPatientDetail, 
    changeAddpatientDetailFilters,
    updatePatientStatus,
    patientstatusResponse,
    // dashboard
    getAddCardDetail,
    getChartapiDetail,
    changeAddChartDetailFilters,
    getAddDashboardDetail,
    changeAdddashboardDetailFilters,

    // service patient by id
    getAddServicepatientDetail,
    changeAddservicepatientDetailFilters,
    getViewMedpresDetail,
    updatePrescriptionStatus,
    servicepresstatusResponse,
}
