import React, { useEffect, useState } from 'react';
import { Space, Row, Col, Form, Card, Button, Drawer, Flex, Select } from 'antd';
import moment from 'moment';

import AddMedicine from './AddMedicine';
import { apiCalls } from '../../shared/Apis';
import { HospiceInput } from '..';

const { Option } = Select;

const AddPrescriptionDrawer = ({ visiblepres, onClose, getAddServicepatientDetail, patientid }) => {
    const [form] = Form.useForm();
    const [nursetype, setNurseType] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ medicinefield, setMedicineField ] = useState([])

    const handleMedChange = (key, value, index) => {
        const updatedFields = form.getFieldValue('medicines') || [];
        updatedFields[index] = {
            ...updatedFields[index],
            [key]: value,
        };
        setMedicineField(updatedFields);
        form.setFieldsValue({ medicines: updatedFields });
    };


    useEffect(() => {
        if (visiblepres) {
            getNurseType();
        } else {
            form.resetFields();
        }
    }, [visiblepres]);

    const getNurseType = async () => {
        let result = await apiCalls.appointmentApi.getNurseType();
        if (result) setNurseType(result);
    };

    const CreateApiAppointment = async (formData) => {
        setLoading(true);
        try {
            const result = await apiCalls.appointmentApi.CreateApiAppointment({
                ...formData,
                patientID: patientid,
                nurseID: nursetype.find(nurse => nurse.firstName === formData.nurseID)?.id,
            });
            setLoading(false);
            getAddServicepatientDetail();
            if (result) onClose();
            form.resetFields();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    const onFinish = async () => {
        try {
            let formData = await form.validateFields();
            formData = {
                ...formData,
                medicines: formData.medicines.map((medicine, index) => ({
                    ...medicine,
                    fromDate: moment(medicine.fromDate).format('YYYY-MM-DD'),
                    toDate: moment(medicine.toDate).format('YYYY-MM-DD'),
                    morning: medicine.morning ? 1 : null,
                    afterNoon: medicine.afterNoon ? 1 : null,
                    night: medicine.night ? 1: null,
                    medicineincre: index + 1,
                }) 
            ),
            };
            CreateApiAppointment(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };


    

    
    

    return (
        <div>
            <Drawer
                title='Add Prescription'
                onClose={onClose}
                open={visiblepres}
                width={1000}
                footer={
                    <Flex justify="end" gap={'small'}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={loading} onClick={() => form.submit()}>
                            Confirm
                        </Button>
                    </Flex>
                }
                closeIcon={false}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Space direction='vertical' size={15} className='w-100'>
                        <Card className='shadow-in border0 radius-12'>
                            <Row gutter={16} className='mb-3'>
                                <Col span={24}>
                                    <Form.Item
                                        label="Nurse name"
                                        name="nurseID"
                                        rules={[{ required: true, message: 'Please choose nurse name' }]}
                                    >
                                        <Select placeholder="Choose nurse name">
                                            {nursetype.map(nurse => (
                                                <Option key={nurse.id} value={nurse.firstName}>
                                                    {`${nurse.firstName} ${nurse.lastName}`}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Flex vertical gap={0}>
                                        <HospiceInput
                                            textArea
                                            name='patientConditions'
                                            label='Patient current condition'
                                            placeholder='Add patient conditions'
                                            required
                                            message='Please enter patient conditions'
                                            value={form.getFieldValue("patientConditions") || ''}
                                        />
                                    </Flex>
                                </Col>
                                <Col span={24}>
                                    <AddMedicine form={form} handleMedChange={handleMedChange} />
                                </Col>
                            </Row>
                        </Card>
                    </Space>
                </Form>
            </Drawer>
        </div>
    );
};

export { AddPrescriptionDrawer };
