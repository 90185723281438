import React, { useEffect, useState } from 'react';
import { Space, Row, Col, Form, Typography, Dropdown, Card, Table, Button, Switch, Flex, Select } from 'antd';
import { MyInput, ModuleTopHeading, ActionButton, ViewPateintDrawer } from '../../components';
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi, TableLoader } from '../../shared';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

let timer;

const Patients = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { addpatientDetail, loadingAddPatientDetail, filters, pagination } = useSelector(state => state?.addpatient);
    const [visible, setVisible] = useState(false);
    const [patientid, setPatientId] = useState(null);
    const [order, setOrder] = useState(1);

    const items = [
        { label: <a href="#">A-Z</a>, key: '0' },
        { label: <a href="#">Z-A</a>, key: '1' },
    ];

    useEffect(() => {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, ...pagination, pageNo: 1 }));
        form.setFieldsValue({ ...filters });
    }, []);

    const filter = (order) => {
        let data = form.getFieldsValue();
        dispatch(actionsApi?.getAddPatientDetail({ ...data, order, ...pagination, pageNo: 1 }));
        dispatch(actionsApi?.changeAddpatientDetailFilters({ ...data, order }));
    };

    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, pageNo, pageSize }));
    };

    function searchHandler(name, pageNo = 1, pageSize = 20) {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, name, pageNo, pageSize }));
    }

    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        filter(key);
    };

    const debounce = (func, delay) => {
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearchHandler = debounce(searchHandler, 400);

    const onChange = (value, row) => {
        // const newStatus = checked ? 1 : 2;
        const newStatus = value === 'Active' ? 1 : 
        value === 'Pending' ? 2 :
        value === 'InActive' ? 3 : value ;
        dispatch(actionsApi?.patientstatusResponse({ id: row.id, status: newStatus }));
        dispatch(actionsApi?.updatePatientStatus({ id: row.id, status: newStatus }));
    };

    const columns = [
        {
            title: 'Patient Name',
            dataIndex: 'firstName',
            render: (_, row) => `${row?.firstName} ${row?.lastName}`
        },
        {
            title: 'Age',
            dataIndex: 'age',
        },
        {
            title: 'Disease',
            dataIndex: 'note',
        },
        {
            title: 'Time Stamp',
            dataIndex: 'dateTime',
            render: dateTime => dateTime,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,
            render: (status, row) => (
                <Space>
                    <img src={
                        status === 1 ? "/assets/icons/online.png" : 
                        status === 2 ? "/assets/icons/pending.png" :
                        status === 3 ? "/assets/icons/offline.png" : ''
                    } 
                    width={'12px'} alt="" />
                    <Text style={{ transform: "translateY(-2px)", display: 'block' }}>
                        {
                            status === 1 ? 'Active' : 
                            status === 2 ? 'Pending' : 
                            status === 3 ? 'Inactive':''
                        }
                    </Text>
                </Space>
            )
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View Item'
                        icon={<EyeOutlined />}
                        onClick={() => { setVisible(true); setPatientId(row?.id) }}
                        type='ghost'
                    />
                    {/* <Switch
                        size='small'
                        checked={row.status === 1}
                        onChange={(checked) => onChange(checked, row)}
                    /> */}
                    <Select
                        onChange={(value) => onChange(value, row)}
                        className='fs-12'
                        defaultValue={
                            row?.status === 1 ? 'Active' :
                            row?.status === 2 ? 'Pending' :
                            row?.status === 3 ? 'InActive' : ''
                        }
                    >
                        <Option value="Active" >Active</Option>
                        <Option value="Pending">Pending</Option>
                        <Option value="InActive">InActive</Option>
                    </Select>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Card className='radius-12 border0'>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Patients' />
                    </Col>
                    <Col span={24}>
                        <Form form={form} layout="vertical">
                            <Flex gap={'small'} className='w-100' align='end'>
                                <MyInput
                                    name='firstName'
                                    label='Search'
                                    placeholder='Search'
                                    value={form.getFieldValue('firstName') || ''}
                                    className='w-100'
                                    onChange={(e) => debouncedSearchHandler(e.target.value, 1, 20)}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                        onClick
                                    }}
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                    className='margin-top'
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns}
                            dataSource={addpatientDetail}
                            scroll={{ x: 1000 }}
                            rowKey="id"
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button>Total: {total}</Button>,
                            }}
                            loading={{ ...TableLoader, spinning: loadingAddPatientDetail }}
                        />
                    </Col>
                </Row>
            </Card>

            <ViewPateintDrawer
                visible={visible}
                patientid={patientid}
                onClose={() => { setVisible(false); setPatientId(null) }}
            />
        </div>
    );
};

export { Patients };
