import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../../../constants';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd';

export const getChartapiDetail = createAsyncThunk(
    'getChartapiStatistics',
    async ({ year, order }, { dispatch }) => {
        const { userToken } = checkAuthorization();
        dispatch(gettingAddChartDetail());

        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ year, order })
        };

        try {
            const response = await fetch(domainUrl + `/physicianGraph`, requestOptions);
            const result = await response.json();
            if (result?.success) {
                dispatch(addchartDetailResponse(result?.data));
            } else {
                throw result?.message;
            }
        } catch (error) {
            dispatch(addchartDetailClear());
            message.error(error);
        }
    }
);

const AddChartSlice = createSlice({
    name: 'chartapi',
    initialState: {
        data: null,
        loading: false,
        addchartDetail: null,
        loadingAddChartDetail: false,
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        statisticsClear: (state) => {
            state.data = null;
            state.loading = false;
        },
        gettingAddChartDetail: (state) => {
            state.loadingAddChartDetail = true;
            state.addchartDetail = null;
        },
        addchartDetailResponse: (state, action) => {
            state.addchartDetail = action.payload;
            state.loadingAddChartDetail = false;
        },
        addchartDetailClear: (state) => {
            state.addchartDetail = null;
            state.loadingAddChartDetail = false;
        },
        changeAddChartDetailFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const {
    statisticsClear,
    gettingAddChartDetail,
    addchartDetailResponse,
    addchartDetailClear,
    changeAddChartDetailFilters,
} = AddChartSlice.actions;
export default AddChartSlice.reducer;
