import React from 'react'
import { Card, Col, Row} from 'antd'
import { AppointmentList } from './AppointmentList'
import { Consultant } from './Consultant'

const AppointConsultComponent = () => {

  return (
    <div>
        <Card className='radius-12 border0 shadow-in'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                  <AppointmentList />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {AppointConsultComponent}