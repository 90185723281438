import { patientApi } from './PatientApi'
import { appointmentApi } from './AddAppointment'
import { changepassApi } from './ChangePassApi'
import { forgotpasswordApi } from './ForgotPasswordApi'

const apiCalls={
    patientApi,
    appointmentApi,
    changepassApi,
    forgotpasswordApi,
}
export {apiCalls}