import React, { useEffect, useState } from 'react';
import { Card, Col, Flex, Row, Typography } from 'antd';
import './index.css';
import { ModuleTopHeading } from '../PageComponents';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CalendarEvents } from './CalendarEvents';
import { checkAuthorization, domainUrl } from '../../shared';

const localizer = momentLocalizer(moment);

const PlannerComponents = () => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([]);

    // useEffect(() => {
    //     fetchEvents(currentDate);
    // }, [currentDate]);

    // const fetchEvents = (date) => {
    //     const { userToken } = checkAuthorization();
    //     const currentMonth = moment(date).month() + 1;
    //     var myHeaders = new Headers();
    //     myHeaders.append('Content-Type', 'application/json');
    //     myHeaders.append('Authorization', userToken);
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         redirect: 'follow',
    //         body: JSON.stringify({ month: currentMonth }),
    //     };
    
    //     fetch(domainUrl + `/getCurrentMonthAppointment`, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             const data = result?.data;
    //             if (data && typeof data === 'object') {
    //                 const formattedEvents = Object.keys(data).flatMap(date => {
    //                     return data[date].map(event => ({
    //                         start: new Date(date),
    //                         end: new Date(date), 
    //                         title: event.title,   
    //                         ...event               
    //                     }));
    //                 });
    //                 setEvents(formattedEvents);
    //                 console.log(formattedEvents);
    //             } else {
    //                 throw new Error('Failed to fetch events');
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error fetching events:', error);
    //         });
    // };
    

    // const handleViewChange = (date) => {
    //     setCurrentDate(date);
    // };

    const EventList = [
        {
            start: moment("2024-07-10T10:00:00").toDate(),
            end: moment("2024-07-10T11:00:00").toDate(),
            title:'Hello',
            status:1,
        },
        {
            start: moment("2024-07-11T10:00:00").toDate(),
            end: moment("2024-07-11T11:00:00").toDate(),
            title:'Hello',
            status:2,
        },
        {
            start: moment("2024-07-12T10:00:00").toDate(),
            end: moment("2024-07-12T11:00:00").toDate(),
            title:'Hello',
            status:3,
        }
    ]

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Card>
                        <Flex vertical gap={'large'}>
                            <ModuleTopHeading
                                name='Appointments'
                            />
                            <Calendar
                                localizer={localizer}
                                events={EventList}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                views={['month', 'week', 'day']}
                                components={{
                                    event: CalendarEvents
                                }}
                                formats={{
                                    monthHeaderFormat: (date) => moment(date).format('DD MMMM YYYY'),
                                }}
                            />
                        </Flex>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export { PlannerComponents };
