import React,{ useEffect } from 'react'
import { Divider, Flex, Modal, Spin, Typography} from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi,TableLoader } from '../../../shared'

const { Text } = Typography

const PrescriptionViewModal = ({visible, onClose, presid}) => {

  const dispatch = useDispatch()
  const { addviewmedDetail, nursedetail, loadingViewMedDetail } = useSelector(state => state?.viewmedicineprescription)

  useEffect(() => {
    if(presid){
      dispatch(actionsApi?.getViewMedpresDetail({ id: presid?.id}))
    }
  }, [presid])


  return (
    <Modal
        title={visible ? '#'+presid.id.substr(0,5) + ' ' + '( '+ moment(presid?.date).format('MMM Do YYYY') +' )' :''}
        centered
        open={visible}
        onOk={onClose}
        onCancel={onClose}
        width={800}
        footer={false}
      ><Divider className='my-2 divder-color' />
        <Flex gap={'small'} className='pt-2'>
          <Text strong>Nurse: </Text>
          <Text>
            {
             '#'+nursedetail?.id.substr(0,3) + ' ' + nursedetail?.firstName + ' ' + nursedetail?.lastName
            }
          </Text>
        </Flex>
        <Flex gap="small" vertical className="pb-2">
          <Text strong>Medicine:</Text>
            {loadingViewMedDetail ? (
              <Spin spinning={loadingViewMedDetail} />
            ) : (
              <>
                {addviewmedDetail?.uniqueMedicines?.map((data, index) => (
                  <Flex vertical key={index} className="px-3">
                    <Text>
                      <strong className="text-capitalize">{`${index + 1}) ${data?.medicine}`}</strong> - {data?.description}
                    </Text>
                    <Text>{`Note: ${data?.note}`}</Text>
                  </Flex>
                ))}
              </>
            )}
        </Flex>
      </Modal>
  )
}

export {PrescriptionViewModal}