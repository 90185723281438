import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	addpatient,
	cardapi,
	chartapi,
	dashboard,
	servicepatient,
	viewmedicineprescription,
} from "./action"
const store = configureStore({
	reducer: {
		login,
		addpatient,
		cardapi,
		chartapi,
		dashboard,
		servicepatient,
		viewmedicineprescription,
	},
})
export default store