import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";


const CreateApiAppointment = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);
    // console.log('Prescription data', formData)
    // return
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return (
        fetch(domainUrl + '/createPrescription', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } else {
                message.error('Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};



const getPatientType= ()=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    return (
        fetch(domainUrl + "/patientListForPhysician", {
            method: "GET",
            headers: myHeaders
        })
        .then(response => response.json())
        .then(result => {
            if (result?.success) 
                return result?.data
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}

const getNurseType= ()=>{
  const {userToken} = checkAuthorization()
  var myHeaders = new Headers()
  myHeaders.append('Authorization', userToken)
  return (
      fetch(domainUrl + "/nurseListForPhysician", {
          method: "GET",
          headers: myHeaders
      })
      .then(response => response.json())
      .then(result => {
          if (result?.success) 
              return result?.data
          else
              throw result?.message
      })
      .catch(error => {
          message.error(error)
          return 0
      })
  )
}


const appointmentApi = {
    CreateApiAppointment,
    getPatientType,
    getNurseType,
}

export {appointmentApi}
