import React from 'react'
import { AppointConsultComponent, CardComponent, BarChartComponent } from '../../components'
import { Space } from 'antd'
import { Patients } from '../Patients'

const Dashboard = () => {
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <CardComponent />
        <BarChartComponent />
        <AppointConsultComponent />
        <Patients />
    </Space>
  )
}

export {Dashboard}