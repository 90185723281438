import React, { useEffect, useRef, useState } from 'react'
import { Space, Row, Col, Form, Typography, Card, Drawer, Avatar, Flex, message } from 'antd'
import { HospiceInput, ModuleTopSmhead, ActionButton, PatientPrescriptionTable } from '..';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { checkAuthorization, domainUrl } from '../../shared';


const ViewPateintDrawer = ({visible, onClose, patientid}) => {

    const [form] = Form.useForm()
    const [ singledata, setSingleData ] = useState(null)
    

    useEffect(()=>{
        if(patientid){
            SingleView()
        }
    },[patientid])

   
    const SingleView=()=>{
        const {userToken} = checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type","application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method:'GET',
            headers: myHeaders,
            redirect:'follow'
        }
        return(
            fetch(domainUrl + `/viewPatientPhysician/${patientid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result?.data)
                if (result?.success) {
                    const data = result?.data
                    setSingleData(data)
                    form.setFieldsValue({
                        ...data,
                        ckfirstName:data?.care_taker?.firstName,
                        cklastName:data?.care_taker?.lastName,
                        ckemail:data?.care_taker?.email,
                        ckphoneNumber:data?.care_taker?.phoneNumber,
                        relationship:data?.care_taker?.relationship,
                        secondryNumber:data?.care_taker?.secondryNumber,
                        ckaddress:data?.care_taker?.address,
                    })                 
                } 
                else 
                    throw result?.message
            })
            .catch(error => {
                message.error(error.message || 'Something went wrong');
            })
        )
    }

    const downloadfile = (path,filename)=> {
        const url = document.createElement("a");
        url.href = path;
        url.setAttribute('download',filename);
        document.body.appendChild(url)
        url.click();
        document.body.removeChild(url)
    }


  return (
    <div>
        <Drawer
            onClose={onClose}
            open={visible}
            width={1300}
            footer={false}
            closeIcon={false}
        >
            <div>
                <Form
                    
                    form={form}
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Space direction='vertical' size={15} className='w-100'>
                        <div className='w-100 space-between'>
                            <Flex gap={'small'}>
                                <Avatar
                                    size={50}
                                    icon={<img src={singledata?.profileImage} width={120} />}
                                />
                                <div>
                                    <Typography.Title level={5} className='m-0 text-capitalize'>
                                        {
                                            singledata?.firstName + ' ' + singledata?.lastName
                                        }
                                    </Typography.Title>
                                    <Typography.Text className='grayish fs-12'>
                                        Patient
                                    </Typography.Text>
                                </div>
                            </Flex>
                            <ActionButton
                                icon={<CloseOutlined />}
                                onClick={onClose}
                                type='ghost'
                            />
                        </div>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Personal Details'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='firstName'
                                        label='First name'
                                        value={form.getFieldValue("firstName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='lastName'
                                        label='Last name'
                                        value={form.getFieldValue("lastName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='note'
                                        label='Disease'
                                        value={form.getFieldValue("note") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='phoneNumber'
                                        label='Phone no'
                                        value={form.getFieldValue("phoneNumber") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='age'
                                        label='Age'
                                        value={form.getFieldValue("age") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='address'
                                        label='Address'
                                        placeholder='Enter address details'
                                        value={form.getFieldValue("address") || ''}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Caretaker Details'
                                        />
                                    </div>
                                </Col>
                                
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='ckfirstName'
                                        label='First name'
                                        placeholder='Enter first name'
                                        value={form.getFieldValue("ckfirstName") || ''}
                                        disabled 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='cklastName'
                                        label='Last name'
                                        placeholder='Enter last name'
                                        value={form.getFieldValue("cklastName") || ''}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='ckemail'
                                        label='Email address'
                                        placeholder='Enter email address'
                                        value={form.getFieldValue("ckemail") || ''}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='ckphoneNumber'
                                        label='Phone number'
                                        placeholder='Enter phone number'
                                        value={form.getFieldValue("ckphoneNumber") || ''}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='relationship'
                                        label='Relationship'
                                        placeholder='Enter your relationship with patient'
                                        value={form.getFieldValue("relationship") || ''}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='secondryNumber'
                                        label='Sec. Number'
                                        placeholder='Enter secondary phone number'
                                        value={form.getFieldValue("secondryNumber") || ''}
                                        disabled
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='ckaddress'
                                        label='Address'
                                        placeholder='Enter street address'
                                        value={form.getFieldValue("ckaddress") || ''}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Medical Record'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Space direction='vertical' size={10} className='w-100'>
                                        {
                                            singledata?.medical_report?.map((report,index)=>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {
                                                            report?.name
                                                        }
                                                    </Typography.Text>
                                                    <ActionButton
                                                        title='Download'
                                                        icon={<DownloadOutlined />}
                                                        onClick={() => downloadfile(report?.path,report?.name)}
                                                        type='ghost'
                                                    />
                                                </div>
                                            )
                                        }
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <PatientPrescriptionTable patientid={patientid} />
                                </Col>
                            </Row>
                        </Card>
                    </Space>
                </Form>
            </div>
        </Drawer>
    </div>
  )
}

export {ViewPateintDrawer}